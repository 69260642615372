<template>
<el-pagination
        :small="small"
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :layout="layout"
        :page-sizes="pageSizes"
        :total="total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
</template>

<script>
// import { scrollTo } from '/src/utils/scroll-to';

export default {
    name: 'CPagination',
    props: {
        total: {
            required: true,
            type: Number,
        },
        page: {
            type: Number,
            default: 1,
        },
        limit: {
            type: Number,
            default: 20,
        },
        pageSizes: {
            type: Array,
            default() {
                return [10, 20, 30, 50];
            },
        },
        layout: {
            type: String,
            default: 'prev, pager, next',
        },
        small: {
            type: Boolean,
            default: true,
        },
        background: {
            type: Boolean,
            default: true,
        },
        autoScroll: {
            type: Boolean,
            default: true,
        },
        hidden: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentPage: {
            get() {
                return this.page;
            },
            set(val) {
                this.$emit('update:page', val);
            },
        },
        pageSize: {
            get() {
                return this.limit;
            },
            set(val) {
                this.$emit('update:limit', val);
            },
        },
    },
    methods: {
        handleSizeChange(val) {
            this.$emit('pagination', { page: this.currentPage, limit: val });

            // if (this.autoScroll) {
            //     scrollTo(0, 800);
            // }
        },
        handleCurrentChange(val) {
            this.$emit('pagination', { page: val, limit: this.pageSize });

            // if (this.autoScroll) {
            //     scrollTo(0, 800);
            // }
        },
    },
};
</script>
